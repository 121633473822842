import PageWrapper from './src/components/layout/pageWrapper';
import React from 'react';

export const onRouteUpdate = () => {
  if (window.location.hostname !== 'localhost') {
    let mediavineScript = document.getElementById('mediavineScript');
    if (mediavineScript) {
      mediavineScript.remove();
    }
    mediavineScript = document.createElement('script');
    mediavineScript.id = 'mediavineScript';
    mediavineScript.src = 'https://scripts.mediavine.com/tags/cafe-au-soul.js';
    mediavineScript.async = true;
    mediavineScript.dataset.noptimize = '1';
    mediavineScript.dataset.cfasync = 'false';
    document.body.appendChild(mediavineScript);
  }

  // console.log('location: ', location);
  if (location.pathname.includes('contact')) {
    let captchaScript = document.getElementById('captchaScript');
    if (captchaScript) {
      captchaScript.remove();
    }
    captchaScript = document.createElement('script');
    captchaScript.id = 'captchaScript';
    captchaScript.async = true;
    captchaScript.defer = true;
    captchaScript.src = 'https://www.google.com/recaptcha/api.js';
    document.body.appendChild(captchaScript);
  }
};

export const wrapPageElement = ({ element, props }) => <PageWrapper {...props}>{element}</PageWrapper>;
