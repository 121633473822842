exports.components = {
  "component---src-pages-3-weeks-dreamwork-next-steps-js": () => import("./../../../src/pages/3-weeks-dreamwork-next-steps.js" /* webpackChunkName: "component---src-pages-3-weeks-dreamwork-next-steps-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-astrology-next-steps-js": () => import("./../../../src/pages/astrology-next-steps.js" /* webpackChunkName: "component---src-pages-astrology-next-steps-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-download-essential-i-ching-pdf-js": () => import("./../../../src/pages/download-essential-i-ching-pdf.js" /* webpackChunkName: "component---src-pages-download-essential-i-ching-pdf-js" */),
  "component---src-pages-dreams-inspired-by-dreams-js": () => import("./../../../src/pages/dreams/inspired-by-dreams.js" /* webpackChunkName: "component---src-pages-dreams-inspired-by-dreams-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oracles-dream-dictionary-js": () => import("./../../../src/pages/oracles/dream-dictionary.js" /* webpackChunkName: "component---src-pages-oracles-dream-dictionary-js" */),
  "component---src-pages-oracles-iching-js": () => import("./../../../src/pages/oracles/iching.js" /* webpackChunkName: "component---src-pages-oracles-iching-js" */),
  "component---src-pages-oracles-tarot-js": () => import("./../../../src/pages/oracles/tarot.js" /* webpackChunkName: "component---src-pages-oracles-tarot-js" */),
  "component---src-pages-personal-dream-interpretation-next-steps-js": () => import("./../../../src/pages/personal-dream-interpretation-next-steps.js" /* webpackChunkName: "component---src-pages-personal-dream-interpretation-next-steps-js" */),
  "component---src-pages-reading-js": () => import("./../../../src/pages/reading.js" /* webpackChunkName: "component---src-pages-reading-js" */),
  "component---src-pages-taoist-coaching-using-i-ching-next-steps-js": () => import("./../../../src/pages/taoist-coaching-using-i-ching-next-steps.js" /* webpackChunkName: "component---src-pages-taoist-coaching-using-i-ching-next-steps-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-dream-js": () => import("./../../../src/templates/dream.js" /* webpackChunkName: "component---src-templates-dream-js" */),
  "component---src-templates-iching-js": () => import("./../../../src/templates/iching.js" /* webpackChunkName: "component---src-templates-iching-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-tarot-js": () => import("./../../../src/templates/tarot.js" /* webpackChunkName: "component---src-templates-tarot-js" */)
}

