import React, { useState, createContext } from 'react';
import '../../styles/layout.css';
import MemberDialog from '../member-dialog';
import { navigate } from 'gatsby';
import { useLocation } from '@gatsbyjs/reach-router';
import queryString from 'query-string';

const onRedirectCallback = (appState) => {
  // Use Gatsby's navigate method to replace the url
  navigate(appState?.returnTo || '/articles', { replace: true });
};

export const SiteContext = createContext({
  memberDialogOpen: false,
  setMemberDialogOpen: () => {},
  globalDreamSearch: '',
  setGlobalDreamSearch: () => {},
  globalTarotFormState: '',
  setGlobalTarotFormState: () => {},
  isAuthenticated: '',
  setIsAuthenticated: () => {},
});

const PageWrapper = ({ children }) => {
  const location = useLocation();
  // fake authentication coming from newsletter email link and set in local storage
  let member = queryString.parse(location.search)?.he7ekiu === '33hkj78';
  if (member) {
    if (typeof window !== 'undefined') {
      if (!window.localStorage.getItem('he7ekiu')) {
        window.localStorage.setItem('he7ekiu', '33hkj78');
      }
    }
  } else {
    if (typeof window !== 'undefined') {
      member = window.localStorage.getItem('he7ekiu') === '33hkj78';
    }
  }
  const [memberDialogOpen, setMemberDialogOpen] = useState(false);
  const [globalDreamSearch, setGlobalDreamSearch] = useState('');
  const [globalTarotFormState, setGlobalTarotFormState] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(member);
  const value = {
    memberDialogOpen,
    setMemberDialogOpen,
    globalDreamSearch,
    setGlobalDreamSearch,
    globalTarotFormState,
    setGlobalTarotFormState,
    isAuthenticated,
    setIsAuthenticated,
  };

  return (
    <SiteContext.Provider value={value}>
      {children}
      <MemberDialog memberDialogOpen={memberDialogOpen} setMemberDialogOpen={setMemberDialogOpen} />
    </SiteContext.Provider>
  );
};

export default PageWrapper;
