import React from 'react';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { dangerMsgStyle, formContainer } from './subscriptionForm.module.css';

const PREFIX = 'SubscriptionForm';

const classes = {
  textfield: `${PREFIX}-textfield`,
  button: `${PREFIX}-button`,
};

const Root = styled('div')(({ theme }) => {
  return {
    [`& .${classes.textfield}`]: {
      width: '200px',
      color: '#000',
      background: 'white',
      [theme.breakpoints.up('md')]: {
        '&': {
          width: '400px',
        },
      },
    },
    [`& .${classes.button}`]: {
      color: '#fff',
      backgroundColor: '#859c33',
      padding: '0 30px',
      fontWeight: 700,
      borderRadius: 0,
    },
  };
});

class SubscriptionForm extends React.Component {
  state = {
    email: '',
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.message !== this.props.message && this.state.email) {
      if (this.props.message === 'captcha') {
        window.open(`${this.props.url}&EMAIL=${encodeURIComponent(this.state.email)}`);
        this.setState({
          email: '',
        });
      }
    }
  }

  handleInputChange = (event) => {
    event.preventDefault();

    const target = event.target;
    const value = target.value;
    this.setState({
      email: value,
    });
  };

  submit = (e) => {
    e.preventDefault();

    const { email } = this.state;

    if (email) {
      const { status, message, onValidated } = this.props;
      return (
        email &&
        email.indexOf('@') > -1 &&
        onValidated({
          EMAIL: email,
        })
      );
    }
  };

  render() {
    const { status, message, onValidated } = this.props;
    return (
      <Root>
        <div style={{ textAlign: 'center' }}>
          {status === 'success'
            ? 'Thank you for joining the members center at cafeausoul.com! 😍 Please check your email for further instructions.'
            : null}
        </div>
        <form onSubmit={(e) => this.submit(e)}>
          <div className={formContainer}>
            {status === 'sending' ? (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress color="primary" />
              </div>
            ) : (
              <>
                <TextField
                  // id="standard-error-helper-text"
                  required
                  name="email"
                  type="email"
                  label="Email Address"
                  variant="filled"
                  disabled={status === 'sending'}
                  classes={{ root: classes.textfield }}
                  onChange={this.handleInputChange}
                />
                <Button
                  variant="contained"
                  color="primary"
                  disabled={status === 'sending'}
                  classes={{ root: classes.button }}
                  onClick={(e) => this.submit(e)}
                  type="submit"
                >
                  Submit
                </Button>
              </>
            )}
          </div>
          {status === 'error' && message !== 'captcha' && (
            <>
              <br />
              <div className={dangerMsgStyle} dangerouslySetInnerHTML={{ __html: message }} />
            </>
          )}
        </form>
      </Root>
    );
  }
}

export default SubscriptionForm;
